import { Form, Dropdown, InputGroup, FloatingLabel } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { Subject, Category } from "../../components/SubNav/filters/resroucesFilterCat";
import { useEffect } from "react";

export default function ResourceDetails () {

    const Grades = [];
        for(let i = 1 ; i < 13; i++) {
            Grades.push(i)
        }
    const currentResource = useSelector(state=>state.currentResource)
    const dispatch = useDispatch();
    const setCurrentResource = (type, update) => {
        dispatch({ type: 'SET_ALLOW_SUBMIT', payload: true})
        dispatch({type:'SET_CURRENT_RESOURCE', payload: {
            ...currentResource,
            [type]:(update.value||update)
        }})
    }
    useEffect(()=>{
        currentResource._id&&dispatch({type: 'SET_ALLOW_SUBMIT', payload: true})
        currentResource.Title.length>0?
            dispatch({type: 'SET_ALLOW_SUBMIT', payload: true})
            :
            dispatch({type: 'SET_ALLOW_SUBMIT', payload: false})
    },[])
    useEffect(()=>{
        dispatch({type: 'SET_ALLOW_SUBMIT', payload: 
            //empty title
            (currentResource.Title&&currentResource.Title.length!==0)&&
            !(currentResource.internalUse==="false"&&
                (!currentResource.priceMonthly&&currentResource.priceMonthly==0||
                !currentResource.priceYearly&&currentResource.priceYearly==0)
            )&&
            (!currentResource._id?currentResource.Workbook.size&&currentResource.Workbook.type==="application/pdf":true)
            &&
            (currentResource.Solutions.size?currentResource.Solutions.type==="application/pdf":true)
            &&
            (currentResource.Cover.size?currentResource.Cover.type.indexOf("image")>=0:true)
        })
    },[currentResource])
    return (
    <div>
        <FloatingLabel
            label={'Resource title*'}
            className="secondary-font text-danger px-3"
        >
            <Form.Control
                type="text" 
                placeholder="hello world"
                required
                value={currentResource.Title??[]}
                onChange={(e)=>setCurrentResource("Title", e.target.value)}
                className="border-0 border-bottom rounded-0"
            />
        </FloatingLabel>
        <div 
            className="text-start py-2 px-2 mt-4 secondary-font"
            style={{background:"rgba(0,0,0,0.1)"}}
        >
        <div className="">
        <div className="d-flex flex-wrap">
        <Dropdown className='p-0 mb-2'>
            <Dropdown.Toggle variant='outline-dark' >
                {Category.filter(x=>
                    x.value===currentResource.Category
                )[0].name}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {Category.map((item, index)=>
                    index!==0&&<Dropdown.Item 
                        key={index} 
                        className='' 
                        onClick={()=>
                            setCurrentResource("Category", item)}
                    >
                        {item.name}
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
            </Dropdown>

            <Dropdown className='p-0 mb-2'>
            <Dropdown.Toggle variant='outline-dark' className='mx-2'>
                {Subject.filter(x=>x.value===currentResource.Subject)[0].name}
            </Dropdown.Toggle>

            <Dropdown.Menu className=''>
                {Subject.map((item, index)=>
                    index!==0&&<Dropdown.Item key={index} className='' onClick={()=>
                        setCurrentResource("Subject", item)}
                    >{item.name}</Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
        <Dropdown className='p-0 mb-2'>
            <Dropdown.Toggle variant='outline-dark'>
                {currentResource.Level==='13'?"Others grade":'Grade '+currentResource.Level}
            </Dropdown.Toggle>
            <Dropdown.Menu className=''>
                {Grades.map((item, index)=>
                <Dropdown.Item className='' key={index} href="" onClick={()=>
                    setCurrentResource("Level", item)}>Grade {item}</Dropdown.Item>
                )}
                <Dropdown.Item className='' href="" onClick={()=>
                    setCurrentResource("Level", 13)}>Other</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        </div>
        </div>
       <InputGroup
             className="border"
        >
            <Form.Control 
                type="file"
                placeholder="cover page"
                className={currentResource.Cover.size?
                    currentResource.Cover.type.indexOf("image")<0?"bg-danger text-white":"bg-input-filled"
                    :
                    ""}
                onChange={(e)=>setCurrentResource("Cover", e.target.files[0])}
            />
            <InputGroup.Text>
            Cover
            </InputGroup.Text>
        </InputGroup >
        {!currentResource._id&&
        <InputGroup className="border">
            <Form.Control 
                type="file"
                placeholder="Asset pdf file"
                className={(currentResource.Workbook.size?
                    currentResource.Workbook.type!=="application/pdf"?"bg-danger text-white":"bg-input-filled"
                    :
                    ""
                )}
                onChange={(e)=>setCurrentResource("Workbook", e.target.files[0])}
            />
            <InputGroup.Text>
            Workbook*
            </InputGroup.Text>
        </InputGroup>
        }
        <InputGroup
             className="border"
        >
            <Form.Control 
                type="file"
                placeholder="Solution pdf file"
                className={currentResource.Solutions.size?
                    currentResource.Solutions.type!=="application/pdf"?"bg-danger text-white":"bg-input-filled"
                    :
                    ""
                }
                onChange={(e)=>setCurrentResource("Solutions", e.target.files[0])}
            />
            <InputGroup.Text>
            Solution
            </InputGroup.Text>
        </InputGroup>
        {(currentResource.internalUse==="false"&&
                (!currentResource.priceMonthly&&currentResource.priceMonthly==0||
                !currentResource.priceYearly&&currentResource.priceYearly==0)
            )&&<p className="text-danger ms-3">All prices must be specified.</p>}
        <div>
        <Form className="my-3 px-2">
            <Form.Check
                type="switch"
                checked={currentResource.internalUse==="true"??false}
                className="text-dark"
                label="Internal use only"
                onChange={()=>setCurrentResource("internalUse", currentResource.internalUse==="true"?"false":"true")}
            />
        </Form>
        </div>
        </div>
        {currentResource.internalUse==="false"&&<div className="my-2 px-2 bg-white pb-2">
            <FloatingLabel
                label="Monthly price ($CAD)"
                className="text-dark"
            >
                <Form.Control 
                    type="number" 
                    placeholder="0.0"
                    value={currentResource.priceMonthly??[]}
                    className="bg-none border-0 border-bottom rounded-0 mb-2"
                    onChange={(e)=>setCurrentResource("priceMonthly", e.target.value)}
                />
            </FloatingLabel>
            <FloatingLabel 
                label="Yearly price ($CAD)"
                className="text-dark"
            >
                <Form.Control 
                    type="number" 
                    placeholder="0.0"
                    value={currentResource.priceYearly??[]}
                    className="bg-none border-0 border-bottom rounded-0 mb-2"
                    onChange={(e)=>setCurrentResource("priceYearly", e.target.value)}
                />
            </FloatingLabel>
        </div>}
        </div>
    )
}