import { Form, FloatingLabel } from "react-bootstrap"

export default function LicenseList(props) {
    const {
        licenses,
        updateList
    } = props
    return (
        <Form className="overflow-y-scroll py-2 my-2 text-start">
            {licenses.map((license, index)=>
                <Form.Group key={index} className="mb-5 mx-1 p-2 " style={{background: "rgba(0,0,0,0.1)"}}>
                    <FloatingLabel className="col me-1 mb-3 p-0" label="License name">
                        <Form.Control 
                            value={license.name}
                            className="col"
                            onChange={(e)=>updateList("PUT",
                                {...license, name: e.target.value}
                            )}
                        />
                    </FloatingLabel>
                    <Form.Label className="m-0"><b>Description</b></Form.Label>
                    <div>
                    {license.description.map(function(line, index) {
                        return <div className="d-flex mb-1" key={index}>
                            <Form.Control
                                className=""
                                value={line}
                                as="textarea"
                                onChange={(e)=>updateList("PUT",
                                    {...license, description: [...license.description.map((descLine, idx)=>idx===index?
                                        e.target.value
                                        :
                                        descLine
                                    )]}
                                )}
                            />
                        </div>})}
                    </div>
                    <Form.Label className="m-0"><b>Price</b></Form.Label>
                    <div className="row">
                        <FloatingLabel className="col me-1 p-0" label="Monthly">
                            <Form.Control
                                value={license.priceMonthly}
                                className="col"
                                onChange={(e)=>updateList("PUT",
                                    {...license, priceMonthly: e.target.value}
                                )}
                            />
                        </FloatingLabel>
                        <FloatingLabel className="col p-0" label="Yearly">
                            <Form.Control
                                value={license.priceYearly}
                                className="col"
                                onChange={(e)=>updateList("PUT",
                                    {...license, priceYearly: e.target.value}
                                )}
                            />
                        </FloatingLabel>
                    </div>
                </Form.Group>
            )}
        </Form>
    )
}