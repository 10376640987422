import { Button, Form, FloatingLabel } from "react-bootstrap"

export default function Tierlist (props) {
    const {
        tiers,
        updateList
    } = props;
    return (
        <Form className="overflow-y-scroll py-2 my-2 text-start">
        {tiers.map(function(tier, index) {
          return <Form.Group className="mb-5 mx-1 p-2 " key={index} style={{background: "rgba(0,0,0,0.1)"}}>
            <FloatingLabel className="secondary-font m-0 mb-3 d-flex" label="Tier name" placeholder="tier name">
            <Form.Control 
                value={tier.name}
                onChange={(e)=>updateList('PUT', {...tier, name: e.target.value})}
            />
            {tier.order!==1&&<Button
                variant="outline-danger"
                className="ms-2 col-auto"
                onClick={(e)=>updateList('DELETE', tier)}
            >Remove tier</Button>}
            </FloatingLabel>
            <div className="mb-3">
            <Form.Label className="m-0"><b>Description</b></Form.Label>
            {index !== 0 && <p>Everything in the previous tier plus:</p>}
              {tier.description.map(function(line, index) {
                return <div className="d-flex mb-1" key={index}>
                    <Form.Control 
                        value={line} 
                        onChange={(e)=>{
                            updateList('PUT', 
                                {...tier, description: [...tier.description.map((descLine, idx)=>idx===index?
                                    e.target.value
                                    :
                                    descLine
                                )]}
                                )
                            }}
                    />
                    <Button 
                        variant="outline-danger"
                        className="ms-2 col-auto"
                        onClick={()=>updateList('PUT', 
                            {...tier, description: [...tier.description.filter((descLine, idx)=>idx!==index)]}
                        )}
                    >Remove row</Button>
                </div>
              })}
            <div>
            <Button 
                variant="outline-primary"
                onClick={()=>updateList('PUT', 
                    {...tier, description: [...tier.description, ""]}
                )}
            >Add row</Button>
            </div>
            </div>
            <div className="mb-3">
            <Form.Label className="m-0"><b>Permission</b></Form.Label>
                <div className="row">
                    <Form.Check
                        type="switch"
                        checked={tier.permission.allow_assign_role}
                        onChange={()=>updateList('PUT', 
                            {...tier, 
                            permission: {
                                ...tier.permission,
                                allow_assign_role: !tier.permission.allow_assign_role
                            }}
                        )}
                        label="Allow assign role"
                    />
                    <Form.Check
                        type="switch"
                        checked={tier.permission.banner}
                        onChange={()=>updateList('PUT', 
                            {...tier, 
                            permission: {
                                ...tier.permission,
                                banner: !tier.permission.banner
                            }}
                        )}
                        label="Allow change banner"
                    />
                    <Form.Check
                        type="switch"
                        checked={tier.permission.solutions}
                        onChange={()=>updateList('PUT', 
                            {...tier, 
                            permission: {
                                ...tier.permission,
                                solutions: !tier.permission.solutions
                            }}
                        )}
                        label="Solutions pairing"
                    />
                    {tier.order!==1&&<Form.Check
                        type="switch"
                        checked={tier.permission.charge_of_service}
                        onChange={()=>updateList('PUT', 
                            {...tier, 
                            permission: {
                                ...tier.permission,
                                charge_of_service: !tier.permission.charge_of_service
                            }}
                        )}
                        label="Service charge"
                    />}
                    <div className="row p-0">
                        <FloatingLabel className="col p-0 me-1" label="Group size" placeholder="group size">
                            <Form.Control 
                                value={tier.permission.group_size}
                                onChange={(e)=>updateList('PUT', 
                                    {...tier, 
                                    permission: {
                                        ...tier.permission,
                                        group_size: e.target.value
                                    }}
                                )}
                            />
                        </FloatingLabel>
                        <FloatingLabel className="col p-0 me-1" label="User size"  placeholder="user size">
                            <Form.Control 
                                value={tier.permission.users_size}
                                onChange={(e)=>updateList('PUT', 
                                    {...tier, 
                                    permission: {
                                        ...tier.permission,
                                        users_size: e.target.value
                                    }}
                                )}
                            />
                        </FloatingLabel>
                        <FloatingLabel className="col p-0" label="Assignment storage duration (month)"  placeholder="duration">
                            <Form.Control 
                                value={tier.permission.resource_storage_durations}
                                onChange={(e)=>updateList('PUT', 
                                    {...tier, 
                                    permission: {
                                        ...tier.permission,
                                        resource_storage_durations: e.target.value
                                    }}
                                )}
                            />
                        </FloatingLabel>
                    </div>
                </div>
            </div>
            {tier.order!==1&&<div className="mb-3">
            <Form.Label className="m-0"><b>Prices ($CAD)</b></Form.Label>
            <div className="row">
                <FloatingLabel className="col me-1 p-0" label="Monthly"  placeholder="price">
                    <Form.Control 
                        value={tier.priceMonthly}
                        className="col"
                        onChange={(e)=>updateList('PUT', 
                            {...tier, 
                                priceMonthly: e.target.value
                            }
                        )}
                    />
                </FloatingLabel>
                <FloatingLabel className="col p-0" label="Yearly" placeholder="price">
                    <Form.Control 
                        value={tier.priceYearly}
                        className="col"
                        onChange={(e)=>updateList('PUT', 
                            {...tier, 
                            priceYearly: e.target.value
                            }
                        )}
                    />
                </FloatingLabel>
            </div>
            </div>}

            
          </Form.Group>
        })}
        {tiers.length<3&&<Button 
            variant="outline-primary"
            onClick={()=>updateList('ADD', 'tier')}
        >Add tier</Button>}
        </Form>
    )
}