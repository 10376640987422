import { useEffect, useState } from "react";
import { useDispatch, useSelector} from "react-redux";

import LC, {LiterallyCanvasReactComponent} from "literallycanvas";
import { InputGroup, Form, Button, ToggleButton, ButtonGroup} from "react-bootstrap";
import { overrideDefaultToolGraphics, pointSize, minCoordinate } from './onload'
import { assetsSrc } from "../../../../API/config"

import DrawingAPI from "../../../../API/drawing";
import RightSlide from "../../../components/rightSlide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";

import PieSocket from "piesocket-js";


export default function EditTool (props) {
    const {
        setChannelId,
        socket,
        setSocket,
        allowSolutions,
        setAllowSolutions,
        showSolutionsSlide,
    } = props

    const [imgs, setImgs] = useState([]);
    const [img, setImg] = useState([]);
    const [backgroundWorksheet, generateBackgroundWorksheet] = useState([]);
    const [drawingId, setDrawingId] = useState(null);
    const [drawingSnapshot, setDrawingSnapshot] = useState(null)
    const [showBanner, setShowBanner] = useState(false);
    const [fullyLoaded, isFullyLoaded] = useState(false);
    const [channel, setChannel] = useState(null)

    const dispatch = useDispatch();

    const token = useSelector(state=>state.token)
    const myInfo = useSelector(state=>state.myInfo)
    const tgtUser = useSelector(state=>state.tgtUser)
    const currentResource = useSelector(state=>state.currentResource)
    const bookmark = useSelector(state=>state.bookmark)
    const srcGroup = useSelector(state=>state.srcGroup)


    let nIntervId;
    const save = async (snapshot) => {
        clearInterval(nIntervId);
        nIntervId = setInterval(async()=>{
        var values = snapshot.drawing.getSnapshot(['shapes']).shapes.map(value=>(
            {
                'Image':[drawingSnapshot.offset?[drawingSnapshot.offset[0],drawingSnapshot.offset[1]]:[1,1]],
                'Line':[[value.data.x1, value.data.y1], [value.data.x2, value.data.y2]],
                'Ellipse':[[value.data.x, value.data.y], 
                    [value.data.width<0&&value.data.x+value.data.width, value.data.height<0&&value.data.y+value.data.height]],
                'Text':[[value.data.x, value.data.y]]
            }[value.className]
            ||
            value.data.pointCoordinatePairs
        )).flat(1)
        //calculate offset mechanism
        var minLocation = [0, 1].map((x)=>minCoordinate(values, x) - pointSize(snapshot.drawing.getSnapshot(['shapes']).shapes
        .filter(shape=>shape.className!=="Image"), x, minCoordinate(values, x)));

        //send calculated data to API
        await DrawingAPI.updateDrawing(token, currentResource._id, tgtUser._id??myInfo._id, drawingId, {
            snapshot: snapshot.drawing.getSnapshot(['shapes']).shapes.length>0?LC.renderSnapshotToImage(snapshot.drawing.getSnapshot(['shapes'])).toDataURL('image/png'):[],
            offset: minLocation
        })
        .catch((err)=>console.log(err))
        await clearInterval(nIntervId);
        nIntervId = null
    }, 3000)
    }

    const generateDrawing = () => {
        if(!(srcGroup._id===1&&!myInfo.admin)){
            setSocket(new PieSocket({
                clusterId: "free.blr2",
                apiKey: "YTKlKNF1hYigYqfIOT5NWm1ZcNDa7T5PPux3o7FS",
                presence: true,
                userId: myInfo._id
            }))
            setChannelId(currentResource._id+(tgtUser._id?tgtUser._id:myInfo._id))
        }
        DrawingAPI.loadDrawing(token, currentResource._id, tgtUser._id??myInfo._id, bookmark.Chapter.toString(), bookmark.Section.toString())
        .then(response=>{
            if(!response.data.DrawingSnapshot){
            DrawingAPI.saveDrawing(token, currentResource._id, tgtUser._id??myInfo._id, {
                snapshot: [],
                bookmark: bookmark
            })
            .then(response=>{
                setDrawingId(response.data)
                setDrawingSnapshot([])
            })
            }else{
                setDrawingSnapshot(response.data.DrawingSnapshot)
                setDrawingId(response.data._id)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    const LcContainer = () => {
    return(
    <>
        <LiterallyCanvasReactComponent
            onInit={canvasInit}
            defaultStrokeWidth={2}
            backgroundShapes={backgroundWorksheet}
            primaryColor={
                tgtUser._id?"#c22929":"#006eff"
            }
            secondaryColor={"transparent"}
            tools={[
                //the first tool will be the default tool on access
                LC.tools.Pan,
                LC.tools.Pencil,
                LC.tools.Eraser,
                LC.tools.Text,

                //polygon tools
                LC.tools.Line,
                // LC.tools.Polygon,
                LC.tools.Ellipse,
                // LC.tools.Rectangle,

    
            ]}
        />
        <div className="position-absolute shadow"
            style={{right:"20px", bottom:"20px"}}
        >
            <InputGroup className="d-flex">
                <Button
                    variant="secondary"
                    id="page-previous"
                >
                    <FontAwesomeIcon
                        icon={faCaretLeft}
                    />
                </Button>
                <InputGroup.Text>Page</InputGroup.Text>
                <Form.Control 
                    className="border-0 hide-arrow"
                    style={{width: 50}}
                    id="page-jump"
                    defaultValue="1"
                    type="number"
                    onClick={(e)=>
                        e.target.select()
                    }
                />
                <InputGroup.Text>/{imgs.length}</InputGroup.Text>
                <Button
                    variant="secondary"
                    id="page-next"
                >
                    <FontAwesomeIcon
                        icon={faCaretRight}
                    />
                </Button>
            </InputGroup>
        </div>
        <ButtonGroup 
            id="color-palette"
            className="position-absolute bg-light shadow rounded d-none flex-column justify-content-center p-2"
            style={{left:50}}    
        >
            <ToggleButton 
                id="pen-006eff"
                className="rounded-5 my-2 border-0"
                style={{backgroundColor:"#006eff", width:28, height:28}}
            />
            <ToggleButton 
                id="pen-c22929"
                className="rounded-5 my-2 border-0"
                style={{backgroundColor:"#c22929", width:28, height:28}}
            />
            <ToggleButton 
                id="pen-339966"
                className="rounded-5 my-2 border-0"
                style={{backgroundColor:"#339966", width:28, height:28}}
            />
        </ButtonGroup>
    </>
    )}
    useEffect(()=>{
        drawingId?
        dispatch({type:'SHOW_PRELOAD', payload: false})
        :
        dispatch({type:'SHOW_PRELOAD', payload: true});
    },[drawingId, backgroundWorksheet])

    useEffect(()=>{
        socket&&socket.subscribe(currentResource._id+(tgtUser._id?tgtUser._id:myInfo._id)).then(res=>
            setChannel(res)
        );
    },[socket])

    const canvasInit = async (lc) => {
        let scale = lc.ctx.canvas.height/lc.ctx.canvas.clientHeight
        if(drawingSnapshot){
            var drawingImg = new Image();
            drawingImg.src = drawingSnapshot.snapshot
            // drawingSnapshot&&lc.loadSnapshot(drawingSnapshot)
            lc.saveShape(LC.createShape('Image', {
                x: drawingSnapshot.offset?drawingSnapshot.offset[0]:0, 
                y: drawingSnapshot.offset?drawingSnapshot.offset[1]:0, 
                image: drawingImg,
                scale: 1
            }))
        }
        lc.on('pan', (data)=>{
            var page = Math.ceil(-(data.y/750/scale))+1
            document.getElementById('page-jump').value = (data.y<0?(page<img.length?page:img.length):1);
        })
        document.getElementById('page-jump').addEventListener("input", ()=>{
            var inputdata = document.getElementById('page-jump').value
            var page = (!inputdata||inputdata<1)?1:(inputdata>img.length?img.length:inputdata)
            lc.setPan(0, (-page+1)*750*scale)
        })
        document.getElementById('page-previous').addEventListener("click", ()=>{
            var inputdata = document.getElementById('page-jump').value>1?
                Math.ceil(document.getElementById('page-jump').value)-1:1
            lc.setPan(0, (-inputdata+1)*750*scale)
        });
        document.getElementById('page-next').addEventListener("click", ()=>{
            var inputdata = document.getElementById('page-jump').value<img.length?
                Math.ceil(document.getElementById('page-jump').value)+1:img.length
            lc.setPan(0, (-inputdata+1)*750*scale)
        });
        document.getElementById('pen-006eff').addEventListener("click",()=>{
            lc.setColor('primary', '#006eff')
        });
        document.getElementById('pen-c22929').addEventListener("click",()=>{
            lc.setColor('primary', '#c22929')
        });
        document.getElementById('pen-339966').addEventListener("click",()=>{
            lc.setColor('primary', '#339966')
        });
        document.querySelector('div[title="Pencil"]').addEventListener("click", ()=>{
            document.getElementById("color-palette").classList.replace('d-none','d-flex')
        })
        document.querySelector(".lc-drawing.with-gui").addEventListener("mouseenter", (e)=>{
            document.getElementById("color-palette").classList.replace('d-flex','d-none')
        })
        //Overrriding default LC tools graphics with FA
        overrideDefaultToolGraphics()

        const sendDrawingtoWebsocket = async (data) => {
            await channel&&channel.publish('drawing',{
                shape: data.shape,
                message: JSON.stringify(data.message)
            })
            if(data.shape){
                await save({
                    drawing: lc,
                })
            }
        }
        lc.on('drawStart', ()=>{
            //save after 5 secconds if no further input
            // listening to drawing change event
            var unsubscribe = lc.on('drawingChange', ()=>{
                var shapesData = LC.util.last(lc.getSnapshot(['shapes']).shapes)
                sendDrawingtoWebsocket(
                    {'Line':{shape:{
                        className:'Line',
                        color: lc.colors.primary,
                        points:{
                            x1: shapesData.data.x1,
                            x2: shapesData.data.x2,
                            y1: shapesData.data.y1,
                            y2: shapesData.data.y2
                        }, 
                    }},
                    "Ellipse":{shape:{
                        className:'Ellipse',
                        color: lc.colors.primary,
                        points:{
                            x: shapesData.data.x,
                            y: shapesData.data.y,
                            width: shapesData.data.width,
                            height: shapesData.data.height
                        }, 
                    }},
                    "Text":{shape:shapesData}
                    }[shapesData.className]
                    ||
                    {shape:{
                        className: shapesData.className,
                        color: lc.colors.primary,
                        points: shapesData.data.pointCoordinatePairs, 
                    }}
                )
            })
            lc.on('drawEnd', ()=>unsubscribe())
        })
        lc.on('toolChange', ()=>{
            Object.getPrototypeOf(lc.tool).name === "Eraser"?
                lc.tool.strokeWidth = 20
                :
                lc.tool.strokeWidth = 2
        })
        lc.on('undo', ()=>{
            lc.getSnapshot(['shapes']).shapes.length<=1?
            lc.redo()
            :
            sendDrawingtoWebsocket({
                shape:{
                    className:'undo',
                }
            })
        })
        //handle undo and redo temporarily, this needs to be update later
        lc.on('redo', ()=>{
            if(LC.util.last(lc.getSnapshot(['shapes']).shapes).className!=='Image'){
                sendDrawingtoWebsocket({shape:{
                    className:LC.util.last(lc.getSnapshot(['shapes']).shapes).className,
                    color: LC.util.last(lc.getSnapshot(['shapes']).shapes).data.pointColor,
                    points:LC.util.last(lc.getSnapshot(['shapes']).shapes).data.pointCoordinatePairs, 
                }})
            }
        })
        channel&&channel.listen("drawing", async (e) => {
            let data = e.shape
            // recieved broadcast data
            if(data){
                {({
                'LinePath':()=>lc.saveShape(LC.createShape(data.className, {
                    points: data.points.map(
                        points=>LC.createShape('Point', {x:points[0], y:points[1], size:data.className==='LinePath'?2:25, color:data.color})
                    )
                })),
                'ErasedLinePath':()=>lc.saveShape(LC.createShape(data.className, {
                    points: data.points.map(
                        points=>LC.createShape('Point', {x:points[0], y:points[1], size:data.className==='LinePath'?2:25, color:data.color})
                    )
                })),
                'Line':()=>lc.saveShape(LC.createShape(data.className, {
                    ...data.points,
                    strokeWidth: 2,
                    color: data.color
                })),
                'Ellipse':()=>lc.saveShape(LC.createShape(data.className, {
                    ...data.points,
                    strokeWidth: 2,
                    strokeColor: data.color
                })),
                // 'undo':()=>lc.loadSnapshot({shapes: lc.getSnapshot(['shapes']).shapes.slice(0, -1)}),
                'undo':()=>lc.loadSnapshot({shapes: lc.getSnapshot(['shapes']).shapes.slice(0, -1)}),
                'Text':()=>lc.saveShape(LC.createShape(data.className,{
                    x: data.data.x-47,
                    y: data.data.y-4,
                    text: data.data.text,
                    color: data.data.color,
                    font: data.data.font
                })),
                'Image':()=>{
                    var drawingImg = new Image();
                    drawingImg.src = drawingSnapshot.snapshot
                    lc.saveShape(LC.createShape('Image', {
                        x: drawingSnapshot.offset?drawingSnapshot.offset[0]:0, 
                        y: drawingSnapshot.offset?drawingSnapshot.offset[1]:0, 
                        image: drawingImg,
                        scale: 1
                    }))}
                }[data.className])()}
            }
        })
        channel&&channel.listen("system:member_joined", function(data){
            console.log(data)
        })
    };
    useEffect(()=>{
        currentResource.Type === 'assignment'?
            generateDrawing()
            :
            bookmark.label&&generateDrawing();
    },[currentResource, bookmark])
        useEffect(()=>{
            if(currentResource.Type==='Workbook'){
                setAllowSolutions(currentResource.Solutions.length>0?currentResource.Solutions[0]._id:false)
                setShowBanner(currentResource.Category)
                setImgs(currentResource.Chapters[bookmark.Chapter].Navigations[bookmark.Section].Worksheets)
                currentResource.Chapters[bookmark.Chapter].Navigations[bookmark.Section].Worksheets.map(()=>setImg(item=>[...item, new Image]))

            }else if(currentResource.Type==='assignment'){
                setAllowSolutions(currentResource.Solution!=='false'?currentResource.Solution:false)
                setShowBanner(currentResource.Category);
                setImgs(currentResource.Worksheets)
                currentResource.Worksheets.map(()=>{
                    setImg(item=>[...item, new Image])
                })
            }
        },[bookmark, currentResource, tgtUser])
        useEffect(()=>{
            generateBackgroundWorksheet(() => {
                let backgroundWorksheets = [];
                
                // Load static banner
                let staticBanner = new Image();
                staticBanner.src = showBanner === "Foundation" ? "/assets/static-banner.jpeg" : "";
                
                // Load dynamic banner (if applicable)
                let dynamicBanner = null;
                if (srcGroup.banner && showBanner === "Foundation") {
                    dynamicBanner = new Image();
                    dynamicBanner.src = `https://d3i7bhlv78zipy.cloudfront.net/banners-upload/${srcGroup.banner}`;
                }
            
                // Load worksheet images
                let loadedCount = 0;
                imgs.sort().forEach((item, index) => {
                    let imgElement = new Image();
                    imgElement.height = 2750;
                    imgElement.width = 2126;
            
                    imgElement.onload = function () {
                        backgroundWorksheets.push(
                            LC.createShape("Image", {
                                x: 0,
                                y: index * 750,
                                image: imgElement,
                                scale: 0.26,
                            }),
                            LC.createShape("Image", {
                                x: 30,
                                y: index * 750 + 40,
                                image: staticBanner,
                                scale: 0.38,
                            })
                        );
            
                        // Add dynamic banner only if it's loaded
                        if (dynamicBanner) {
                            let dynBannerClone = new Image();
                            dynBannerClone.src = dynamicBanner.src; // Clone it to avoid async issues
            
                            dynBannerClone.onload = function () {
                                backgroundWorksheets.push(
                                    LC.createShape("Image", {
                                        x: 165,
                                        y: index * 750 + 89,
                                        image: dynBannerClone,
                                        scale: 0.376,
                                    })
                                );
            
                                loadedCount++;
                                if (loadedCount === imgs.length) {
                                    isFullyLoaded(true);
                                }
                            };
                        } else {
                            loadedCount++;
                            if (loadedCount === imgs.length) {
                                isFullyLoaded(true);
                            }
                        }
                    };
            
                    imgElement.src =
                        assetsSrc +
                        (currentResource.Type !== "uploads" ? "vendors-upload/" : "users-upload/") +
                        (currentResource.Type === "assignment" ? currentResource.Origin : currentResource._id) +
                        "/" +
                        item;
                });
            
                return backgroundWorksheets;
            });
        },[img, showBanner, currentResource, tgtUser])
        
    return (
        <div className="h-100 w-100">
            <div
                className="h-100 overflow-hidden d-flex justify-content-end"
            >
                {allowSolutions&&<RightSlide
                    showSolutionsSlide={showSolutionsSlide}
                    items={imgs.map(item=>allowSolutions+'/'+item)}
                    category={showBanner}
                />}
                {fullyLoaded&&<LcContainer/>}
            </div>
        </div>
    )
}
