import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShop } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { localDeployment } from '../../API/config';
import { useDispatch } from 'react-redux';

export default function LeftNav (props) {
    const {
        navigations,
        screen
    } = props
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [expand, setExand] = useState(false)
    return(
    <div 
        className="bg-secondary p-0 position-relative" 
        style={{width: isTablet?'auto':'64.5px', zIndex: 1050 }}
        onMouseEnter={()=>!isMobile&&setExand(true)}
        onMouseLeave={()=>!isMobile&&setExand(false)}
    >
        <div className={"h-100 col-auto row align-items-between bg-secondary " + (expand&&'drop-shadow position-absolute')}>
        <div className="mt-auto p-0 d-flex flex-column">
        {navigations.map((navigation, index)=>
        <Button
            key={index}
            variant='secondary' 
            className='rounded-0 py-3 text-start d-flex'
            disabled={screen.indexOf(navigation.type.toLowerCase())>0}
            onClick={()=>{
                dispatch({type:"RESET_FILTERS"})
                navigate((navigation.homepage?'./':('./'+navigation.type.toLowerCase())), {replace: true})
            }}
        >
            <FontAwesomeIcon
                className='text-white p-0 mx-2'
                icon={navigation.iconOnLeftNav}
            />
            {expand&&navigation.type}        
            {isTablet&&navigation.type}        
        </Button>
        )}
        {!localDeployment&&<Button 
            variant='dark'
            className='rounded-0 py-3 text-start d-flex'
            onClick={async ()=>{
                await dispatch({type:'SET_PORTAL', payload:'EpenStore'})
                await navigate('/epenStore', {replace: true})
            }}
        >                
            <FontAwesomeIcon
                className='text-white p-0 mx-2'
                icon={faShop}
            />
            {expand&&'Store'}
            {isTablet&&'Store'}
        </Button>}
        </div>
        </div>
    </div>
)
}