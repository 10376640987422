import UploadsAPI from "../../../API/uploads";

export default async function* handleUpload(token, data) {
    if (!data.Title) return;

    const postData = {
        "Title": data.Title,
        "Category": data.Category,
        "Subject": data.Subject,
        "Level": data.Level,
        "priceMonthly": data.priceMonthly,
        "priceYearly": data.priceYearly,
        "internalUse": data.internalUse,
        "portal": data.portal,

        "Cover":data.Cover.size>0?data.Cover:null,
    }
    if(data.Workbook.size>0){
        try {
            const res = await UploadsAPI.uploadsPost(token, null, {
                ...postData,
                "Type": "Workbook",
            });
    
            for await (const status of granualUpload(token, data.Workbook, res.data)) {
                yield status;
            }
    
            if (data.Solutions.size > 0) {
                const solutionRes = await UploadsAPI.uploadsPost(token, null, {
                    "Type": "Solutions",
                    "portal": postData.portal,
                    "book_id": res.data._id,
                });
    
                for await (const status of granualUpload(token, data.Solutions, solutionRes.data)) {
                    yield status;
                }
            }
    
        } catch (err) {
            yield `Upload failed: ${err.message}`;
        }
    }else{
        try {
            // Upload solutions in chunks
            const solutionRes = await UploadsAPI.updateUpload(token, {...postData, "_id":data._id, "updateSolution": true});

            if(data.Solutions.size>0){
                for await (const status of granualUpload(token, data.Solutions, solutionRes.data)) {
                    yield status;
                }
            }else{
                return solutionRes;
            }
        }  catch (err) {
            yield `Upload failed: ${err.message}`;
        }
    }
}


async function* granualUpload(token, file, metadata) {
    const CHUNK_SIZE = 20 * 1024 * 1024;
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
        const start = chunkIndex * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);

        const formData = new FormData();
        formData.append("file", chunk);
        formData.append("chunk_index", chunkIndex);
        formData.append("total_chunks", totalChunks);
        formData.append("portal", "vendor");
        formData.append("resource_data", JSON.stringify(metadata));

        try {
            const res = await UploadsAPI.uploadsPost(token, null, formData)
            yield res.data!==''?res.data:'Uploading '+((chunkIndex + 1)/totalChunks*50).toFixed(0)+'%';
        } catch (err) {
            yield `Error uploading chunk ${chunkIndex + 1}: ${err.message}`;
        }
    }
}


