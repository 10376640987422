import { InputGroup, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

export default function TOCEditableEntry (props) {
    const { 
        cindex,
        index,
        isChapter,
        navigate,
        updatePreSubmitTOCdata
    } = props;

    const currentResource = useSelector(state=>state.currentResource);
    const bookmark = useSelector(state=>state.bookmark);
    const dispatch = useDispatch();
return(
    <InputGroup 
        className="secondary-font align-items-center py-2" 
    >
        <div className={"col-auto d-flex justify-content-start"}>
        <div 
            className="px-2 text-danger pointer hover rounded-1"
            onClick={async()=>{
                if(isChapter){
                    if(currentResource.Chapters[cindex].Navigations[0]&&
                        (!currentResource.Chapters[cindex].Navigations[0].Worksheets||
                        currentResource.Chapters[cindex].Navigations[0].Worksheets.length>0)){
                        dispatch({ type: 'SET_NOTIFICATION', payload: {
                            show: true,
                            code: 400,
                            message: 'Chapter is not empty'
                        }})
                    }else{
                        if(bookmark.Chapter===cindex&&bookmark.Section===index){
                            await navigate((bookmark.Chapter-1), (currentResource.Chapters[cindex-1].Navigations.length-1), "")
                        }
                        await updatePreSubmitTOCdata({
                            ...currentResource,
                            Chapters: [
                                ...currentResource.Chapters.filter((item, idx)=>idx!==cindex)
                            ]
                        });
                    }
                }else{
                    if(currentResource.Chapters[cindex].Navigations[index].Worksheets&&currentResource.Chapters[cindex].Navigations[index].Worksheets.length>0){
                        dispatch({ type: 'SET_NOTIFICATION', payload: {
                            show: true,
                            code: 400,
                            message: 'Section is not empty'
                        }})
                    }else{
                        if(bookmark.Section!==0){
                            if(bookmark.Chapter===cindex&&bookmark.Section===index){
                                navigate(bookmark.Chapter, (bookmark.Section-1), "")
                                const prevState = currentResource
                                prevState.Chapters[cindex].Navigations = currentResource.Chapters[cindex].Navigations.filter((entry, idx)=>idx!==index)
                                await updatePreSubmitTOCdata({
                                    ...prevState
                                });
                            }
                        }else{
                            await navigate((bookmark.Chapter-1), (currentResource.Chapters[cindex-1].Navigations.length-1), "")
                        }
                    }
                }

            }}
        >
            <FontAwesomeIcon icon={faTrash}/>
        </div> 
        </div>
        <Form.Control
            type="text"
            placeholder="Enter chapter/section title..."
            value={isChapter?
                currentResource.Chapters[cindex].Label
                :
                currentResource.Chapters[cindex].Navigations[index].Label
            ||[]}
            className="border-0 border-bottom rounded-0 bg-none"
            onChange={(e)=>{
                const prevState = currentResource;
                if(isChapter){
                    prevState.Chapters[cindex].Label = e.target.value
                    updatePreSubmitTOCdata({
                        ...prevState
                    })
                }else{
                    prevState.Chapters[cindex].Navigations[index].Label = e.target.value
                    updatePreSubmitTOCdata({
                        ...prevState
                    })
                }
            }}
        />
        {!isChapter&&
        <div 
            className="bg-secondary text-white p-1 rounded me-3 pointer hover"
            onClick={()=>navigate(cindex, index, currentResource.Chapters[cindex].Navigations[index].Label)}    
        >
            <FontAwesomeIcon
                icon={faFolderOpen}
            />
        </div>}
    </InputGroup >
    )
}
