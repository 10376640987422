import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import NewObjectModel from "../../components/Models/newObjectModel";
import ResourceDetails from "../../screen/Resources/resourceDetails";
import { Button } from "react-bootstrap";

export default function AddResource (props) {
    const {
        resourceAdd,
        loadResources,
    } = props;
    const dispatch = useDispatch();
    const resourceInsert = async (data) => {
        //pull stats out here.
        await dispatch({type: 'SHOW_PRELOAD', payload: true});
        try {
            for await (const res of resourceAdd(data)) {
                await res.progressId?
                dispatch({ type: 'SET_CURRENT_PROGRESS', payload: [res.progressId] })
                :
                dispatch({ 
                    type: 'SET_NOTIFICATION', 
                    payload: {
                        show: true,
                        code: 300,
                        stats: res
                    } 
                });
            }
            await loadResources();
            await dispatch({ type: 'RESET_UPDATABLE' });
        } catch (error) {
            // Handle errors and show failure notification
            await dispatch({ 
                type: 'SET_NOTIFICATION', 
                payload: {
                    show: true,
                    code: 500,
                    message: 'Failed to upload resource.'
                } 
            });
        }
        
        await dispatch({type: 'SHOW_PRELOAD', payload: false});
    }
    const setSlideInContent = () => {
        dispatch({type:'SHOW_LEFT_SLIDE', payload: true})
        dispatch({type:'LEFT_SLIDE_CONTENT', payload:
            ()=><NewObjectModel
                    model='resource'
                    itemType={'inventory'}
                    submit={resourceInsert}
                >
                    <ResourceDetails/>
                </NewObjectModel>
            
        })
    }
    return(
    resourceAdd&&
    <Button
        variant="primary"
        onClick={()=>setSlideInContent()}
    >
        <FontAwesomeIcon
            icon={faPlus}
        /> New inventory item 
    </Button>
    )
}