import { Accordion, Button, Card} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import TOCEditableEntry from "./TOCEditableEntry"
import ExpandChapterTrigger from "./expandChapterTrigger";
import TOCaddEntry from "./TOCAddEntry";

import UploadAPI from "../../../../../API/uploads";

export default function TableOfContents (props) {
    const { 
        enableNavigate
    } = props;

    const dispatch = useDispatch();
    const token = useSelector(state=>state.token);
    const currentResource = useSelector(state=>state.currentResource);
    const bookmark = useSelector(state=>state.bookmark);
    const screen = useSelector(state=>state.screen);
    const updatePreSubmitTOCdata = (item) => {
        dispatch({type: 'SET_CURRENT_RESOURCE', payload: item})
    }
    const submitTOC = () => {
        UploadAPI.updateUpload(token, {
            _id:currentResource._id,
            Chapters:currentResource.Chapters
        })
        .then(()=>
            dispatch({ type: 'SET_NOTIFICATION', payload: {
                show: true,
                code: 200,
                message: 'Table of content saved.'
            }})
        )
        .catch(()=>
            dispatch({ type: 'SET_NOTIFICATION', payload: {
                show: true,
                code: 500,
                message: 'Saving table of content failed.'
            }})
        )
    }
    const navigate = (cindex, index, label) =>{
        enableNavigate&&dispatch({type: 'SHOW_LEFT_SLIDE', payload: false});
        dispatch({type: 'SET_BOOKMARK', payload: {
            Chapter: cindex,
            Section: index,
            label: label
        }});
    }
    const editingState = screen.indexOf('vendor')>=0
return(
currentResource.Chapters&&
<>
    <div className="text-white bg-secondary py-3 col order-1">
        <p className="m-0 prime-font">{currentResource.Title}</p>
    </div>
    <Accordion
        className="col-12 order-3 p-0"
        defaultActiveKey={bookmark.Chapter}
    >
    {
        currentResource.Chapters.map(((chapter, cindex)=>
        <Card
            className="rounded-0"
            key={cindex}
        >
                <ExpandChapterTrigger
                    eventKey={cindex}
                    updatePreSubmitTOCdata={updatePreSubmitTOCdata}
                    navigate={navigate}
                    isChapter
                >
                    {chapter.Label}
                </ExpandChapterTrigger>
            <Accordion.Collapse 
                eventKey={cindex}
                style={{backgroundColor:"rgba(0,0,0,0.07)"}}    
            >
                <Card.Body className="p-0 text-end ps-4">
                    <div className={editingState?"py-2":undefined}>
                    {chapter.Navigations&&chapter.Navigations.map((section, index)=>
                        <Card.Header 
                            key={index}
                            className={"border-0 rounded-0 d-flex justify-content-start bg-none "+(!editingState&&"pointer")}
                            onClick={()=>!editingState?navigate(cindex, index, section.Label):'undefine'}
                        >
                        {editingState?
                        <div className="text-end w-100">
                            <TOCEditableEntry
                                cindex={cindex}
                                index={index}
                                navigate={navigate}
                                updatePreSubmitTOCdata={updatePreSubmitTOCdata}
                            />
                        </div>
                        :
                        <p className={"m-0 py-1 ps-1 text-start "
                            + (bookmark.Chapter===cindex&&bookmark.Section===index&&"text-danger")
                        }>{section.Label}</p>
                        }
                        </Card.Header>
                    )}
                    {editingState&&
                    <div className="px-4">
                    <TOCaddEntry
                        cindex={cindex}
                        updatePreSubmitTOCdata={updatePreSubmitTOCdata}
                    />
                    </div>
                    }
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
        ))
    }

    </Accordion>
    {screen.indexOf('vendor')>=0&&
    <div className="m-2 text-end col-12 order-4">
        <TOCaddEntry
            isChapter
            updatePreSubmitTOCdata={updatePreSubmitTOCdata}
        />
        <Button onClick={()=>submitTOC()}>
            Save
        </Button>
    </div>
    }
</>
)}