import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { api, assetsSrc, localDeployment } from '../../../API/config';

export default function Worksheet (props) {

    const {
        src,
        className,
        category,
        previewBanner,
        bannerUploaded,
        onClick
    } = props
    
    const allowPrint = useSelector(state=>state.myInfo.allow_print)
    return (
        <div
            className={(className??undefined)+' overflow-hidden position-relative'}
            onClick={onClick}

        >
            {category&&category!=='Concept'&&
            <div 
                className='w-100'
            >
            <Image
                className='position-absolute'
                style={{width:'88%', left:'5.5%', top:'5.5%'}}
                src={!(allowPrint||localDeployment)?'/assets/static-banner-local.jpg':'/assets/static-banner.jpeg'}
            />
            {bannerUploaded&&<Image
                className='position-absolute'
                style={{top:'12%', right:'6.5%', width:'63.7%'}}
                src={(!previewBanner?"https://d3i7bhlv78zipy.cloudfront.net/banners-upload/":"")+bannerUploaded}
            />}
            </div>}
            <Image
                className='w-100 h-100'
                src={(category!=='sample'?assetsSrc:'')+src}
            />
        </div>
            
    )
}