import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Table from '../../components/Table';
import { 
    addGroup, 
    removeGroup, 
    updateGroup,
    loadGroups
} from "./groupAction";
import RowContainer from "../../components/Table/rowContainer"
import GroupDetails from "./groupDetails";
import { faPlus, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";

export default function Groups () {
    const dispatch = useDispatch()
    const showLeftSlide = useSelector(state=>state.showLeftSlide)
    const token = useSelector(state=>state.token)
    const srcGroup = useSelector(state=>state.srcGroup)
    const [isSubmitted, setIsSubmitted] = useState(false);
    useEffect(()=>{
        dispatch({ type: 'SET_SCREEN', payload: 'groups'})
        dispatch({ type: 'RESET_UPDATABLE'})
    },[showLeftSlide])
    
    const tableEntryTemplates = [
        {
            'label': '',
            'key': '',
            'col':'auto',
            'actions':[]
        },
        {
            'label': 'Name',
            'key': 'name',
            'actions':[]
        },
        {
            'label': 'Inventory Items',
            'key': 'inventory_items',
            'altKey':'sync_with_root',
            'showCount': true,
            'actions':[]
        },
        {
            'label': 'Users',
            'key': 'users',
            'showCount': true,
            'actions':[]
        },
        {
            'label': 'Admins',
            'key': 'admins',
            'showCount': true,
            'actions':[]
        },
        {
            'label': 'Actions',
            'key': 'actions',
            'col':'2',
            'actions':[
                {
                name:'Edit group',
                verb:'PUT',
                icon: faPencil,
                objectDetails: <GroupDetails/>,
                execute: async  function* (data){
                    await updateGroup(token, data).then(() =>
                        dispatch({ type: 'SET_NOTIFICATION', payload: {
                            show: true,
                            code: 200,
                            message: 'Group '+data.name+' updated.'
                        }})
                    )
                }},
                {
                name:'Delete',
                verb:'DELETE',
                icon: faTrash,
                execute: async (data)=>{
                    await removeGroup(token, data).then(() =>
                        dispatch({ type: 'SET_NOTIFICATION', payload: {
                            show: true,
                            code: 200,
                            message: 'Group '+data.name+' deleted.'
                        }})
                    )
                }},
            ]

        },
    ]
    const tableMenuActions = [
        {
            name:'New group',
            verb:'ADD',
            icon: faPlus,
            objectDetails: <GroupDetails/>,
            execute: async (data)=>{
                await addGroup(token, data).then(() =>
                    dispatch({ type: 'SET_NOTIFICATION', payload: {
                        show: true,
                        code: 200,
                        message: 'Group '+data.name+' added.'
                    }})
                )
        }},
    ]

    return (
    <div className="h-100 d-flex align-items-center justify-content-center">
        <Table
            tableEntryTemplates={tableEntryTemplates}
            tableMenuActions={tableMenuActions}
            className="drop-shadow"
            target='group'
            setIsSubmitted={setIsSubmitted}
        >
            <RowContainer
                target='group'
                tableEntryTemplates={tableEntryTemplates}
                extractFilter={(e)=>e.filter(item=>item.name==='groups')[0].value}
                childrenRowKey="children_groups"
                isSubmitted={isSubmitted}
                setIsSubmitted={setIsSubmitted}
                onLoad={(setEntries, filters)=>loadGroups(token, srcGroup._id, filters, setEntries)}
            />    
        </Table>
    </div>
    )
}