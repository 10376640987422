import { useState } from "react"
import { InputGroup, Form, Button } from "react-bootstrap"
import { useSelector } from "react-redux";
import API from "../../../API/users";

export default function Invitation () {

    const [invitationCode, setInvitationCode] = useState();
    const [feedback, setFeedback] = useState([]);

    const token = useSelector(state=>state.token)
    const myInfo = useSelector(state=>state.myInfo)

    const joinGroup = () => {
        API.addUser(token, {
            _id: myInfo._id,
            username: myInfo.username,
            invitation_code: invitationCode,
            type: "invitation"
        })
        .then(response=>{
            if(response.data.code===200){
                setFeedback({
                    code: response.data.code,
                    message: "Successfully joined "+response.data.data[0]+"."
                })
            }else{
                setFeedback({
                    code: response.data.code,
                    message: "Group not found."
                })
            }
        })
        .catch(err=>console.log(err))
    }

    return (
    <div className="row justify-content-center align-items-center h-100">
        <div style={{width: 450}} className='bg-white p-4 drop-shadow rounded'>
            <h4 className="mb-4">Invitation code</h4>
            <InputGroup className="px-3">
                <Form.Control
                className="login-input"
                placeholder="invitation code"
                value={invitationCode}
                onChange={e=>{
                    setInvitationCode(e.target.value)
                }}

                />
            </InputGroup>
            <p className={"mb-0 mt-2 "+(feedback.code===200?"text-success":"text-danger")}>{feedback.message}</p>
            <Button 
                className="mb-3 mt-3 px-5"
                onClick={joinGroup}
            >Join group</Button>
        </div>
    </div>
    )
}