import axios from "axios";
import { api } from "./config";

export default class API {
    static async AddOnProductIndex(token){
        return axios.get(api+'api/addonproducts', {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }

    static async AddOnProductPut(token, data) {
        return axios.put(api+'api/addonproducts/'+null, data, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
}