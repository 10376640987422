export function overrideDefaultToolGraphics (){
    var tools = document.getElementsByClassName("toolbar-button thin-button")
    for ( var i=0; i<tools.length; i++) {
        var faIconChild = document.createElement('i')
        if([...tools][i].title==="Text"){
            faIconChild.className = "fa-solid fa-keyboard"
        }else if ([...tools][i].title==="Pan") {
            faIconChild.className = "fa-solid fa-up-down-left-right"
        }else if ([...tools][i].title==="Zoom out") {
            faIconChild.className = "fa-solid fa-magnifying-glass-minus"
        }else if ([...tools][i].title==="Zoom in") {
            faIconChild.className = "fa-solid fa-magnifying-glass-plus"
        }else if ([...tools][i].title==="Line") {
            faIconChild.className = "fa-solid fa-slash"
        }else if ([...tools][i].title==="Rectangle") {
            faIconChild.className = "fa-regular fa-square"
        }else if ([...tools][i].title==="Polygon") {
            faIconChild.className = "fa-solid fa-draw-polygon"
        }else if ([...tools][i].title==="Ellipse") {
            faIconChild.className = "fa-regular fa-circle"
        }else{
            faIconChild.className = "fa-solid fa-"+ [...tools][i].title.toLowerCase()
        }
        tools[i].style.backgroundImage = null
        tools[i].appendChild(faIconChild)
    }
}

export function pointSize (shapes, locationIndex, minCoordinate) {
    var matchedShapes = shapes.filter(shape=>shape.data.pointCoordinatePairs?
        shape.data.pointCoordinatePairs.filter(x=>x[locationIndex]===minCoordinate).length>0
        :
        shape.data.x1===minCoordinate||shape.data.x2===minCoordinate||shape.data.y1===minCoordinate||shape.data.y2===minCoordinate
    )
    if(matchedShapes.length>0){
        return matchedShapes.some(shape=>shape.className==="ErasedLinePath")?
            matchedShapes.filter(shape=>shape.className==="ErasedLinePath")[0].data.pointSize/2
            :
            matchedShapes.some(shape=>shape.className==="Line"||shape.className==="Ellipse")?
                matchedShapes.filter(shape=>shape.className==="Line"||shape.className==="Ellipse")[0].data.strokeWidth/2
                :
                matchedShapes.filter(shape=>shape.className==="LinePath")[0].data.pointSize/2
    }else{
        return 1;
    }
}

export function minCoordinate (array, locationIndex) {
    return Math.min.apply(null, array.map(x=>x[locationIndex]))
}