import { useEffect, useState } from "react"
import API from "../../../../../API/addOnProduct"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "react-bootstrap"
import Tierlist from "./tierList"
import LicenseList from "./licenseList"

export default function Index (props) {
    const {
        type
    } = props;
    const dispatch = useDispatch();
    const token = useSelector(state=>state.token)
    const [list, setList] = useState([])
    
    useEffect(()=>{
        API.AddOnProductIndex(token)
        .then(res=>{
            setList(res.data)
        })
        .catch(err=>console.log(err))
    },[type])
    
    const updateList = (method, item) => ({
        'ADD':()=>setList([...list, {
                "type": item,
                "name": "",
                "permission": {
                    "allow_assign_role": false,
                    "group_size": 1,
                    "users_size": 20,
                    "resource_storage_durations": 90,
                    "solutions": false,
                    "banner": false,
                    "charge_of_service": false
                },
                "priceMonthly": 20,
                "priceYearly": 240,
                "description": [
                    ""
                ],
                "order": list.length
        }])
        ,
        'PUT':()=>
            setList(list.map(oldItem=>oldItem._id===item._id?
                item
                :
                oldItem
            ))  
        ,
        'DELETE': ()=>
            setList(list.filter(oldItem=>oldItem._id!==item._id))
    }[method])()

    const submit = async () => {
        dispatch({type: "SHOW_PRELOAD", payload: true})
        API.AddOnProductPut(token, list).then(()=>{
            dispatch({ type: 'SET_NOTIFICATION', payload: {
                show: true,
                code: 200,
                message: 'Pricing updated!'
            }})
            dispatch({type: "SHOW_PRELOAD", payload: false})
        })
        .catch(()=>{
            dispatch({ type: 'SET_NOTIFICATION', payload: {
                show: true,
                code: 400,
                message: 'Pricing failed to update.'
            }})
            dispatch({type: "SHOW_PRELOAD", payload: false})
        })
    }
    return (
        <div className="h-100 position-relative px-2 d-flex flex-column">
        {type==='tier'?
            <Tierlist 
                tiers={list.filter(item=>item.type===type).sort((a,b)=>a.order-b.order)}
                updateList={updateList}
            />
            :
            <LicenseList 
                licenses={list.filter(item=>item.type===type)}
                updateList={updateList}    
            />
        }
        <div className="py-2 border-top">
            <Button
                onClick={()=>submit()}
            >Apply change</Button>
        </div>
        </div>
    )
}