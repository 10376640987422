import '../App.css';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Routes,
  Route,
} from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import SubNavContainer from "./components/SubNav";
import ResourceIndex from './screen/Resources/ResourceIndex/index';
import EditTool from './screen/Resources/ResourceEditTools/index';
import LeftSlideIn from './components/LeftSlide/index';
import API from '../API/progress';

import * as routes from './screen/routes';


function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showLeftSlideIn = useSelector(state=>state.showLeftSlide)
  const myInfo = useSelector(state=>state.myInfo)
  const tgtUser = useSelector(state=>state.tgtUser)
  const role = useSelector(state=>state.role)
  const screen = useSelector(state=>state.screen)
  const portal = useSelector(state=>state.portal)
  const SlideInContent = useSelector(state=>state.leftSlideContent)
  const currentProgress = useSelector(state=>state.currentProgress);
  const filters = useSelector(state=>state.filters);

  const showSolutionsSlide = useRef(null);
  const [allowSolutions, setAllowSolutions] = useState(false)
  const [channelId, setChannelId] = useState(null)
  const [socket, setSocket] = useState(false)

  const navHeight = (portal==='EpenStore'||screen.indexOf('password')>=0||screen.indexOf('invitation')>=0||screen.indexOf('global')>=0)?'67px':'127px';
  const appHeight = {
    height: 'calc(100% - '+navHeight+')',
    zIndex: 10
  }
  const navigations = [
      {
        title: 'Account',
        restrictions:false,
        childPath: false
      },
      {
        title: 'Resources',
        restrictions:false,
        childPath: true
      },
      {
        title: 'Groups',
        restrictions: !(role==="admin"),
        childPath: false
      },
      {
        title: 'Users',
        restrictions: !(role==="admin"),
        childPath: false
      },
      {
        title: 'SearchResult',
        restrictions: false,
        childPath: false
      },

      //control panel
      {
        title: 'Password',
        restrictions:false,
        childPath: false
      },
      {
        title: 'Subscription',
        restrictions:false,
        childPath: false
      },
      {
        title: 'Invitation',
        restrictions:(role==="admin"),
        childPath: false
      },
      {
        title: 'Setting',
        childPath: false
      },
      {
        title: 'Global',
        childPath: false
      }
  ]
  
  useEffect(()=>{
    !myInfo&&navigate('./', { replace: true })
  }, [myInfo])
  
  useEffect(()=>{
    if(myInfo){
      dispatch({type:"SET_PORTAL", payload:'EpenApp'})
    }else{
      dispatch({type:"SET_PORTAL", payload:'EpenStore'})
    }
  },[myInfo, screen])

  useEffect(()=>{
    dispatch({type:'SHOW_LEFT_SLIDE', payload: false})
    dispatch({type:'SET_FILTERS', payload: [
      ...filters.filter(item=>item.name!=='users'),
      {
        name: 'users',
        value: {
          role:
            (screen.indexOf('resources')>=0||
            screen.indexOf('resourceIndexBySection')>=0)
          ?'user':'all',
          input: ''
        }
      }
    ]})
    if(socket){
      socket.unsubscribe(channelId);
      setSocket(false);
    }
  },[screen])

  useEffect(()=>{
    if(!showLeftSlideIn){
      screen.indexOf('user')>=0&&dispatch({type:"RESET_USER"})
      screen.indexOf('group')>=0&&dispatch({type:"RESET_GROUP"})
      //reset resource on vendor upload. Switch from show left slide for add and update.
      !(screen.indexOf('assignment')>=0&&tgtUser._id)&&screen.indexOf('resourceIndexBySection')<0&&screen.indexOf('editTool')<0&&dispatch({type:"RESET_RESOURCE"})
      screen.indexOf('resources')<0&&dispatch({ type: 'RESET_FILTERS'})
      dispatch({type:'SET_ALLOW_SUBMIT', payload: false})
    }
  },[showLeftSlideIn])

  useEffect(()=>{
    const polling = currentProgress.length>0&&
    setInterval(()=>API.getStatus(currentProgress[0])
    .then(async res=>
      {
          if(res.data.length>0){
              await dispatch({ type: 'SET_NOTIFICATION', payload: {
                  show: true,
                  code: 300,
                  stats: 'Processing '+res.data[0].upload_type.toLowerCase()+' - '+(Math.round((res.data[0].numernator/res.data[0].denominator).toFixed(2)*50)+50)+"%",
              }})
          }else{
              await dispatch({ type: 'SET_NOTIFICATION', payload: {
                  show: true,
                  code: 200,
                  message: 'Process completed sucessfully!'
              }})
              await dispatch({ type: 'SET_CURRENT_PROGRESS', payload: []})
              await clearInterval(polling);
          }
      }
    )
    .catch(async err=>{
      await alert(err.response.data.error);
      await dispatch({ type: 'SET_NOTIFICATION', payload: {
          show: true,
          code: err.response.status,
          message: err.response.data.error
      }})
      await dispatch({type:"SET_CURRENT_PROGRESS", payload: []})
      await clearInterval(polling);
    })
  , 7000)
  },[currentProgress])


  return (
  <>
    <div className="vh-100 w-100 row">
        {showLeftSlideIn&&
          <LeftSlideIn
            SlideInContent={SlideInContent}
          />
        }
        {myInfo&&(screen.indexOf('password')<0)&&(screen.indexOf('invitation')<0)&&(screen.indexOf('global')<0)&&
        <div className='position-fixed p-0' style={{zIndex:100, top:67, height: 60}}>
          <SubNavContainer
            allowSolutions={allowSolutions}
            showSolutionsSlide={showSolutionsSlide} 
          />
        </div>}
        <div className='ePen-theme-secondary w-100 fixed-bottom p-0' style={appHeight}>
            <Routes>
              {navigations.map((navigation, index)=>
              !navigation.restrictions&&<Route
                key={index}
                path={(index!==0?'/'+navigation.title.toLowerCase():'/')+(navigation.childPath?"/*":"")}
                element={
                    React.createElement(routes[navigation.title])
                }
              />
              )}
              <Route path ="/editTool" element={
                <EditTool
                    socket={socket}
                    setSocket={setSocket}
                    setChannelId={setChannelId}
                    allowSolutions={allowSolutions}                
                    setAllowSolutions={setAllowSolutions}
                    showSolutionsSlide={showSolutionsSlide}

                />
              }/>
              <Route path="/resourceIndexBySection" element={
                  <ResourceIndex
                    navHeight={navHeight}
                  />
              } />
            </Routes>
          </div>
        </div>
    </>
  )
}
export default App;
